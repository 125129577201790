import React, { useRef, useState, useEffect } from "react";
//import video from "../../assets/JTBC_On_Campaign_240709_0.mp4";

const VIDEO = () => {
  const autoplayUrl = "/file/videos/JTBC_On_Campaign_240709_0.mp4";
  //const autoplayUrl = video;

  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const handleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(videoRef.current.muted);
    }
  };

  useEffect(() => {
    const videoElement = document.getElementById("background-video");
    if (videoElement) {
      videoElement.addEventListener("touchstart", (e) => e.preventDefault(), {
        passive: false,
      });
      videoElement.addEventListener("click", (e) => e.preventDefault(), {
        passive: false,
      });
    }
  }, []);

  return (
    <div className="relative flex flex-col justify-center items-center">
      <div className="relative w-full pointer-events-none py-5 px-8 bg-[#FAE5A6] md:py-7 lg:h-120">
        <video
          id="background-video"
          className="w-full h-full pointer-events-none rounded-lg object-cover"
          autoPlay
          muted
          loop
          playsInline
          disablePictureInPicture
          controls={false}
          controlsList="nodownload nofullscreen noremoteplayback"
          style={{
            clipPath: "inset(1% round 15px)",
          }}
        >
          <source src={autoplayUrl} type="video/mp4" />
        </video>
        <div className="absolute text-[5px] md:text-[8px] left-0 bottom-0 pl-12 pb-8 md:pl-14 md:pb-10 lg:pl-16 lg:pb-12">
          <p>출처: 채널: JTBC Brand Design</p>
          <p>
            영상제목: “함께 있지 않아도 함께 | 선한 영향력 (내레이션 배우
            소유진)”
          </p>
          <p>업로드일: 2021. 11. 17, 영상길이: 0:52</p>
        </div>
      </div>
    </div>
  );
};

export default VIDEO;
